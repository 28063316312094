/*-----------------------------------------------------------------------------
Typography
-----------------------------------------------------------------------------*/

$base__font-size: 1rem;
$base__font-weight_normal: 400;
$base__font-weight_bold: 600;
$base__font-family: 'Open Sans', sans-serif;

$header__font-weight_bold: 900;
$header__font-weight_normal: 600;
$header__font-family: 'Fira Sans', sans-serif;
$header__letter-spacing: 0.125rem;

$header__h1_font-size: 3.5rem;
$header__h1_margin: 3.5rem 0 2.5rem 0;
$header__h1_line-height: 4rem;

$header-for-big-mobile__h1_font-size: 3rem;
$header-for-big-mobile__h1_margin: 3rem 0 2rem 0;
$header-for-big-mobile__h1_line-height: 3.5rem;

$header__h2_font-size: 2rem;
$header__h2_margin: 3rem 0 1.5rem 0;
$header__h2_line-height: 2.5rem;

$header__h3_font-size: 1.4rem;
$header__h3_margin: 2rem 0 1rem 0;
$header__h3_line-height: 1.8rem;

$header__h4_font-size: .9rem;
$header__h4_margin: 2rem 0 1rem 0;
$header__h4_line-height: 1.8rem;

$header__h5_font-size: .9rem;
$header__h5_margin: 2rem 0 1rem 0;
$header__h5_line-height: 1.8rem;

$header__h6_font-size: .9rem;
$header__h6_margin: 2rem 0 1rem 0;
$header__h6_line-height: 1.8rem;

$paragraph-big_font-size: 1.4rem;

$paragraph_margin: 2rem 0;
$paragraph_line-height: 1.4rem;

/*-----------------------------------------------------------------------------
Colors
-----------------------------------------------------------------------------*/

$color__white: #fff;

$color__brand: #09814A;
$color__brand_light1: lighten($color__brand, 10%);
$color__brand_light2: lighten($color__brand, 15%);
$color__brand_light3: lighten($color__brand, 25%);
$color__brand_light4: lighten($color__brand, 55%);
$color__brand_light5: lighten($color__brand, 65%);

$color__warning: #EA6044;
$color__warning_light1: lighten($color__warning, 10%);
$color__warning_light2: lighten($color__warning, 15%);
$color__warning_light3: lighten($color__warning, 25%);

$color__black: #262A30;
$color__black_light1: lighten($color__black, 10%);
$color__black_light2: lighten($color__black, 30%);
$color__black_light3: lighten($color__black, 50%);
$color__black_light4: lighten($color__black, 75%);
$color__black_light5: lighten($color__black, 80%);

/*-----------------------------------------------------------------------------
Margins and paddings
-----------------------------------------------------------------------------*/

$padding__horizontal_one: 1.5rem;
$padding__horizontal_double: 3rem;
$padding__horizontal_big: 4rem;
$padding__vertical_one: 1.5rem;
$padding__vertical_double: 3rem;
$padding__vertical_big: 4rem;

$margin__horizontal_one: 1.5rem;
$margin__horizontal_double: 3rem;
$margin__horizontal_big: 4rem;
$margin__vertical_one: 1.5rem;
$margin__vertical_double: 3rem;
$margin__vertical_big: 4rem;

$padding__horizontal_btn-sm: 1.2rem;
$padding__horizontal_btn-default: 1.5rem;
$padding__horizontal_btn-lg: 3rem;
$padding__vertical_btn-sm: 0.3rem;
$padding__vertical_btn-default: 0.5rem;
$padding__vertical_btn-lg: 1rem;


/*----------------------------------------------------------------------------
Animations and opacity
-----------------------------------------------------------------------------*/

$base__animation_speed: .16s;
$base__animation_speed_fast: .08s;
$base__opacity_hover: .85;
$base__opacity_active: .68;


