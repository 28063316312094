@import "variables";

/*-----------------------------------------------------------------------------
Mixins
-----------------------------------------------------------------------------*/

@mixin hover-focus-opacity {
    &:hover, &:focus {
        opacity: $base__opacity_hover;
    }
}

@mixin active-opacity {
    &:active {
        opacity: $base__opacity_active;
    }
}

@mixin light-theme {
    &.light-theme {
        color: $color__white;
    }
}

@mixin active {
    &.active {
        display: inherit;
    }
}

@mixin slide-out-left {
    &.slide-out-left {

        animation: slide-out-left .7s ease-in-out forwards;
    }
}

@mixin slide-in-left {
    &.slide-in-left {

        animation: slide-in-left .7s ease-in-out forwards;
    }
}

@mixin slide-in-right {
    &.slide-in-right {

        animation: slide-in-right .7s ease-in-out forwards;
    }
}

@mixin slide-out-right {
    &.slide-out-right {

        animation: slide-out-right .7s ease-in-out forwards;
    }
}

@mixin fadein{
    &{
        animation: fadein .7s ease-out forwards;
    }
}

@mixin icon-sm {
    display: inline-block;
    padding-left: 34px;
    min-height: 24px;
    background-repeat: no-repeat;
    background-position: left center;
    line-height: 24px;
}

.center {
    text-align: center;
}

.fixed {
    width: 100%;
    position: absolute;
    &.bottom {
        bottom: 0;
    }
    &.left {
        left: 0;
    }
    &.right {
        right: 0;
    }
    &.top {
        top: 0;
    }
}

@keyframes slide-in-left {
    0% {
        left: -100%;
    }
    100% {
        left: 0;
    }
}

@keyframes slide-in-right {
    0% {
        right: -100%;
        left: auto;
    }
    100% {
        right: 0;
        left: auto;
    }
}

@keyframes slide-out-left {
    0% {
        left: 0;
    }
    100% {
        left: -100%;
    }
}

@keyframes slide-out-right {
    0% {
        right: 0;
        left: auto;
    }
    100% {
        right: -100%;
        left: auto;
    }
}

@keyframes fadein {
    0% {
        opacity: 0;
        display: none;
    }
    66% {
        opacity: 1;
        display: inherit;
    }
    100% {
        opacity: 1;
        display: inherit;
    }
}