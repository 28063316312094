@import "variables";
@import "mixins";

#top-menu {
    position: inherit;
    background-color: $color__black_light1;
}

.right-menu {
    ul {
        list-style: none;
        padding-left: 0;
        border-left: 1px solid $color__black_light4;
        margin-top: 5px;
    }
    a {
        display: block;
        width: 100%;
        border: none;
        color: $color__black_light3;
        border-left: 5px solid transparent;
        padding-left: 1.5rem;
        margin-bottom: 0;
        margin-top: 1.5rem;
        margin-left: -3px;
        &:hover, &:focus {
            color: $color__black_light2;
            border-left: 5px solid $color__black_light3;
        }
        &:active {
            color: $color__black_light1;
            border-left: 5px solid $color__black_light2;
        }
        &.active {
            color: $color__black;
            border-left: 5px solid $color__black_light2;
        }
    }
}

.main-content .row:first-child div:first-child p {
    margin-top: 0;
}

article .row:first-child div:first-child p {
    margin-top: 0;
}

.main-content .container-fluid {
    padding-left: 0;
    padding-right: 0;
}

.right-menu li:first-child a {
    margin-top: 0;
}

.container-partners-cards {
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
    &:nth-child(odd) {
        padding-right: 1.5rem;
    }
    &:nth-child(even) {
        padding-left: 1.5rem;
    }
}

.partner-card {
    width: 100%;
    max-width: 50%;
    padding: $padding__vertical_one $padding__horizontal_one;
    display: flex;
    h3 {
        margin-top: 0
    }
    h5 {
        margin-top: $margin__vertical_one;
        margin-bottom: $margin__vertical_one/3;
    }
    p {
        margin-top: 0;
        margin-bottom: 0;
    }
    .container-partner-card {
        background-color: $color__black_light4;

        padding: $padding__vertical_one $padding__horizontal_one;
        border-radius: 6px;
        display: flex;
        flex-direction: column;
        flex: 1;
        &.lanit {
            background-image: url(../img/lanit.png);
        }
        &.netqis {
            background-image: url(../img/netqis.png);
        }
        &.rosinfo {
            background-image: url(../img/rosinfo.png);
        }
        &.ccs {
            background-image: url(../img/ccs.png);
        }
    }
}

@media all and (max-width: 992px) {
    .right-menu {
        ul {
            text-align: center;
            border-left: 0;
        }
        a {
            border: none;
            margin: 0;
            padding: $padding__vertical_one/2 0;
            &.active {
                border-left: 0;
                background-color: $color__black_light4;
            }
            &:hover, &:focus {
                color: $color__black_light2;
                background-color: $color__black_light4;
                border: none;
            }
            &:active {
                color: $color__black_light1;
                background-color: $color__black_light4;
                border: none;
            }
        }

    }
    .partner-card {
        max-width: 100%;
    }

}

@media all and (max-width: 768px) {
    .partner-card {
        padding: $padding__vertical_one 0;
    }
}